'use strict';

var setdatalayer = require('../gtm/setdatalayer');

/**
 * Retrieves the relevant pid value
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $('.product-detail:not(".bundle-item")').data('pid');
    }

    return pid;
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    return $el && $('.set-items').length
        ? $($el).closest('.product-detail').find('.quantity-select')
        : $('.quantity-select');
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Process attribute values associated with an attribute that uses buttons as selectors
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processButtonValues(attr, $productContainer) {
    // disable all buttons to start
    if (attr.id == 'width') {
        $('.button-group-width').find('.pdp-variation-button');
    }

    if (attr.id == 'size') {
        $('.button-group-size').find('.pdp-variation-button');
    }

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find(
            '[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value + '"]'
        );
        // change button url to account for other selected attributes
        $attrValue.attr('href', attrValue.url);

        if (attrValue.orderableAttribute) {
            $attrValue.removeClass('strikethrough');
            $attrValue.attr('aria-disabled', false);
        } else {
            $attrValue.addClass('strikethrough');
            $attrValue.attr('aria-disabled', true);
            $attrValue.attr('aria-description', 'Unavailable');
        }

        if (attrValue.selected) {
            $attrValue.addClass('selected');
        } else {
            $attrValue.removeClass('selected');
        }
    });
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processSwatchValues(attr, $productContainer) {
    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find(
            '[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value + '"]'
        );
        var $swatchAnchor = $attrValue.parent();

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            $attrValue.parent().addClass('selected').attr('aria-pressed', 'true');
            $swatchAnchor
                .parents('.attribute')
                .find('.swatch-display-value')
                .text($attrValue.data('attr-display-value'));
        } else {
            $attrValue.removeClass('selected');
            $attrValue.parent().removeClass('selected').attr('aria-pressed', 'false');
        }

        if (attrValue.url) {
            $swatchAnchor.attr('href', attrValue.url);
        } else {
            $swatchAnchor.removeAttr('href');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable');
        $attrValue.parent().removeClass('selectable unselectable');

        $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
        $attrValue.parent().addClass(attrValue.selectable ? 'selectable' : 'unselectable');
    });

    var $mainAttrContainer = $productContainer.find('[data-attr="' + attr.id + '"]');
    if ($mainAttrContainer.find('.swatch-container.selected').length == 0) {
        var $swatchDisplayValue = $mainAttrContainer.find('.swatch-display-value');
        $swatchDisplayValue.text($swatchDisplayValue.data('default'));
    }
}

/**
 * Process attribute values associated with an attribute that does not have image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function processNonSwatchValues(attr, $productContainer) {
    var $attr = '[data-attr="' + attr.id + '"]';
    var $defaultOption = $productContainer.find($attr + ' .select-' + attr.id + ' option:first');
    $defaultOption.attr('value', attr.resetUrl);

    if (attr.id == 'width') {
        $('.custom-select.form-control.select-width').find('option').attr('disabled', 'disabled');
        $('.custom-select.form-control.select-width').find('option:first').removeAttr('disabled');
    }

    if (attr.id == 'size') {
        $('.custom-select.form-control.select-size').find('option').attr('disabled', 'disabled');
        $('.custom-select.form-control.select-size').find('option:first').removeAttr('disabled');
    }

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find($attr + ' [data-attr-value="' + attrValue.value + '"]');
        $attrValue.attr('value', attrValue.url).removeAttr('disabled');

        if (!attrValue.selectable) {
            $attrValue.attr('disabled', true);
        }

        if (attr.id == 'width') {
            $('.custom-select.form-control.select-width')
                .find(' [data-attr-value="' + attrValue.value + '"]')
                .removeAttr('disabled');
        }

        if (attr.id == 'size') {
            $('.custom-select.form-control.select-size')
                .find(' [data-attr-value="' + attrValue.value + '"]')
                .removeAttr('disabled');
        }

        if (attrValue.selected) {
            $attrValue.attr('selected');
        }
    });

    // Check if current selected attribute is valid/available
    var $select = $productContainer.find(`.select-${attr.id}`);
    $select.toggleClass('selection-unavailable', !$select.val());
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 *
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAttrs(attrs, $productContainer) {
    // After updating the PDP to use buttons instead of selects, all attributes work like the swatches now
    var attrsWithSwatches = ['color'];
    var attrsWithButtons = ['size', 'width'];

    attrs.forEach(function (attr) {
        if (attrsWithSwatches.indexOf(attr.id) > -1) {
            processSwatchValues(attr, $productContainer);
        } else if (attrsWithButtons.indexOf(attr.id) > -1) {
            processButtonValues(attr, $productContainer);
        } else {
            processNonSwatchValues(attr, $productContainer);
        }
    });
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailability(response, $productContainer) {
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    var divClass = '';
    if (!response.product.readyToOrder) {
        availabilityValue = '<div>' + response.resources.info_selectforstock + '</div>';
    } else {
        if (response.product.availability.isLowStock) {
            divClass = 'low-stock';
        } else {
            divClass = 'in-stock';
        }
        availabilityMessages.forEach(function (message) {
            availabilityValue += `<div class="${divClass}">${message}</div>`;
        });
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources,
    });
}

/**
 * Generates html for promotions section
 *
 * @param {array} promotions - list of promotions
 * @return {string} - Compiled HTML
 */
function getPromotionsHtml(promotions) {
    if (!promotions) {
        return '';
    }

    var html = '';

    promotions.forEach(function (promotion) {
        if (!promotion.cartOnly)
            html += '<div class="callout" title="' + promotion.details + '">' + promotion.calloutMsg + '</div>';
    });

    return html;
}

/**
 * Generates html for product attributes section
 *
 * @param {array} attributes - list of attributes
 * @return {string} - Compiled HTML
 */
function getAttributesHtml(attributes) {
    if (!attributes) {
        return '';
    }

    var html = '';

    attributes.forEach(function (attributeGroup) {
        if (attributeGroup.ID === 'mainAttributes') {
            attributeGroup.attributes.forEach(function (attribute) {
                html += '<div class="attribute-values">' + attribute.label + ': ' + attribute.value + '</div>';
            });
        }
    });

    return html;
}

/**
 * @typedef UpdatedOptionValue
 * @type Object
 * @property {string} id - Option value ID for look up
 * @property {string} url - Updated option value selection URL
 */

/**
 * @typedef OptionSelectionResponse
 * @type Object
 * @property {string} priceHtml - Updated price HTML code
 * @property {Object} options - Updated Options
 * @property {string} options.id - Option ID
 * @property {UpdatedOptionValue[]} options.values - Option values
 */

/**
 * Updates DOM using post-option selection Ajax response
 *
 * @param {OptionSelectionResponse} options - Ajax response options from selecting a product option
 * @param {jQuery} $productContainer - DOM element for current product
 */
function updateOptions(options, $productContainer) {
    options.forEach(function (option) {
        var $optionEl = $productContainer.find('.product-option[data-option-id*="' + option.id + '"]');
        option.values.forEach(function (value) {
            var valueEl = $optionEl.find('option[data-value-id*="' + value.id + '"]');
            valueEl.val(value.url);
        });
    });
}

function updateProductImages() {
    var $primaryImages = $('.primary-images'),
        $productInfo = $('.product-detail'),
        masterId = $productInfo.data('master');

    $primaryImages.addClass('loading');

    // Build attribute data parameters
    var params = {
        pid: masterId,
        quickview: $productInfo.data('quickview'),
    };

    $('[data-attr]').each(function () {
        var $attr = $(this),
            attrId = $attr.data('attr'),
            $attrSelected = $attr.find('span.selected, [selected]');

        $attrSelected.each(function () {
            var $currentAttr = $(this);
            params['dwvar_' + masterId + '_' + attrId] = $currentAttr.data('attr-value');
        });
    });

    $.ajax({
        url: $productInfo.data('image-url'),
        type: 'get',
        data: params,
        success: function (data) {
            $primaryImages.replaceWith(data);
            var carousels = require('../components/carousel'),
                zoom = require('../components/zoom');
            zoom.initialize();
            carousels.initialize();
        },
    });
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer) {
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVaraint;
    if (response.product.variationAttributes) {
        updateAttrs(response.product.variationAttributes, $productContainer);
        isVaraint = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVaraint) {
            $productContainer.parent('.bonus-product-item').data('pid', response.product.id);

            $productContainer.parent('.bonus-product-item').data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    updateProductImages();

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        var $priceSelector = $('.prices:not(.prices-mobile) .price', $productContainer).length
            ? $('.prices:not(.prices-mobile) .price', $productContainer)
            : $('.prices:not(.prices-mobile) .price');
        $priceSelector.replaceWith(response.product.price.html);

        var $priceSelectorMobile = $('.prices.prices-mobile .price', $productContainer).length
            ? $('.prices.prices-mobile .price', $productContainer)
            : $('.prices.prices-mobile .price');
        $priceSelectorMobile.replaceWith(response.product.price.htmlmobile);
    }

    // Update promotions
    $productContainer.find('.promotions').empty().html(getPromotionsHtml(response.product.promotions));

    updateAvailability(response, $productContainer);

    if (isChoiceOfBonusProducts) {
        var $selectButton = $productContainer.find('.select-bonus-product');
        $selectButton.trigger('bonusproduct:updateSelectButton', {
            product: response.product,
            $productContainer: $productContainer,
        });
    } else {
        // Enable "Add to Cart" button if all required attributes have been selected
        $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global')
            .trigger('product:updateAddToCart', {
                product: response.product,
                $productContainer: $productContainer,
            })
            .trigger('product:statusUpdate', response.product);
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty().html(getAttributesHtml(response.product.attributes));
}

/**
 * @typespec UpdatedQuantity
 * @type Object
 * @property {boolean} selected - Whether the quantity has been selected
 * @property {string} value - The number of products to purchase
 * @property {string} url - Compiled URL that specifies variation attributes, product ID, options,
 *     etc.
 */

/**
 * Updates the quantity DOM elements post Ajax call
 * @param {UpdatedQuantity[]} quantities -
 * @param {jQuery} $productContainer - DOM container for a given product
 */
function updateQuantities(quantities, $productContainer) {
    if (!($productContainer.parent('.bonus-product-item').length > 0)) {
        var optionsHtml = quantities
            .map(function (quantity) {
                var selected = quantity.selected ? ' selected ' : '';
                return (
                    '<option value="' +
                    quantity.value +
                    '"  data-url="' +
                    quantity.url +
                    '"' +
                    selected +
                    '>' +
                    quantity.value +
                    '</option>'
                );
            })
            .join('');
        getQuantitySelector($productContainer).empty().html(optionsHtml);
    }
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer) {
    if (selectedValueUrl) {
        $('body').trigger('product:beforeAttributeSelect', { url: selectedValueUrl, container: $productContainer });

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
            success: function (data) {
                handleVariantResponse(data, $productContainer);
                updateOptions(data.product.options, $productContainer);
                updateQuantities(data.product.quantities, $productContainer);
                $('body').trigger('product:afterAttributeSelect', { data: data, container: $productContainer });
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            },
        });
    }
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @return {string} - The provided URL to use when adding a product to the cart
 */
function getAddToCartUrl() {
    return $('.add-to-cart-url').val();
}

/**
 * Parses the html for a modal window
 * @param {string} html - representing the body and footer of the modal window
 *
 * @return {Object} - Object with properties body and footer.
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.choice-of-bonus-product');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * Retrieves url to use when adding a product to the cart
 *
 * @param {Object} data - data object used to fill in dynamic portions of the html
 */
function chooseBonusProducts(data) {
    $('.modal-body').spinner().start();

    if ($('#chooseBonusProductModal').length !== 0) {
        $('#chooseBonusProductModal').remove();
    }
    var bonusUrl;
    if (data.bonusChoiceRuleBased) {
        bonusUrl = data.showProductsUrlRuleBased;
    } else {
        bonusUrl = data.showProductsUrlListBased;
    }

    var htmlString =
        '<!-- Modal -->' +
        '<div class="modal fade" id="chooseBonusProductModal" role="dialog">' +
        '<div class="modal-dialog choose-bonus-product-dialog" ' +
        'data-total-qty="' +
        data.maxBonusItems +
        '"' +
        'data-UUID="' +
        data.uuid +
        '"' +
        'data-pliUUID="' +
        data.pliUUID +
        '"' +
        'data-addToCartUrl="' +
        data.addToCartUrl +
        '"' +
        'data-pageStart="0"' +
        'data-pageSize="' +
        data.pageSize +
        '"' +
        'data-moreURL="' +
        data.showProductsUrlRuleBased +
        '"' +
        'data-bonusChoiceRuleBased="' +
        data.bonusChoiceRuleBased +
        '">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        '    <span class="">' +
        data.labels.selectprods +
        '</span>' +
        '    <button type="button" class="close pull-right" data-dismiss="modal">&times;</button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
    $('.modal-body').spinner().start();

    $.ajax({
        url: bonusUrl,
        method: 'GET',
        dataType: 'html',
        success: function (html) {
            var parsedHtml = parseHtml(html);
            $('#chooseBonusProductModal .modal-body').empty();
            $('#chooseBonusProductModal .modal-body').html(parsedHtml.body);
            $('#chooseBonusProductModal .modal-footer').html(parsedHtml.footer);
            $('#chooseBonusProductModal').modal('show');
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        },
    });
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';
    // show add to cart toast
    if (response.newBonusDiscountLineItem && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
        chooseBonusProducts(response.newBonusDiscountLineItem);
    } else {
        if ($('.add-to-cart-messages').length === 0) {
            $('body').append('<div class="add-to-cart-messages"></div>');
        }

        $('.add-to-cart-messages').append(
            '<div class="alert ' +
                messageType +
                ' add-to-basket-alert text-center" role="alert">' +
                response.message +
                '</div>'
        );

        setTimeout(function () {
            $('.add-to-basket-alert').remove();
        }, 5000);
    }
}

/**
 * Retrieves the bundle product item ID's for the Controller to replace bundle master product
 * items with their selected variants
 *
 * @return {string[]} - List of selected bundle product item ID's
 */
function getChildProducts() {
    var childProducts = [];
    $('.bundle-item').each(function () {
        childProducts.push({
            pid: $(this).find('.product-id').text(),
            quantity: parseInt($(this).find('label.quantity').data('quantity'), 10),
        });
    });

    return childProducts.length ? JSON.stringify(childProducts) : [];
}

/**
 * Retrieve product options
 *
 * @param {jQuery} $productContainer - DOM element for current product
 * @return {string} - Product options and their selected values
 */
function getOptions($productContainer) {
    var options = $productContainer
        .find('.product-option')
        .map(function () {
            var $elOption = $(this).find('.options-select');
            var urlValue = $elOption.val();
            var selectedValueId = $elOption.find('option[value="' + urlValue + '"]').data('value-id');
            return {
                optionId: $(this).data('option-id'),
                selectedValueId: selectedValueId,
            };
        })
        .toArray();

    return JSON.stringify(options);
}

function updateStockNumber(stockNumber) {
    var $stockNumberContainer = $('.stock-number-content');
    if (stockNumber === '') {
        $stockNumberContainer.hide();
    } else {
        $stockNumberContainer.find('.value').text(stockNumber);
        $stockNumberContainer.show();
    }
}

// Accessibility - Keyboard navigation

function showTooltip(e) {
    $(e.currentTarget).children('.swatch-tooltip').css('display', 'inline-block');
    // var productId = e.target.getAttribute('data-product');
    // var swatchTooltip = $(`div[data-product=${productId}]`).filter('[class=swatch-tooltip]')[1];

    // $(swatchTooltip).css('display', 'inline-block');
}

function navigationInTooltip(e) {
    // var activeSwatchTooltip = $('.swatch-tooltip:visible')[0];
    var activeSwatchTooltip = $(e.currentTarget).children('.swatch-tooltip');

    if (e.type === 'mouseleave' || e.type === 'focusout') {
        $(activeSwatchTooltip).css('display', 'none');
        return;
    }

    var activeElement = e.target;
    var lastLink = $(activeSwatchTooltip).children('a').last()[0];

    var swatchesDiv = $(activeSwatchTooltip).siblings()[0];

    if (
        (!e.shiftKey && e.key === 'Tab' && activeElement === lastLink) ||
        (e.shiftKey && e.key === 'Tab' && activeElement === swatchesDiv)
    ) {
        $(activeSwatchTooltip).css('display', 'none');
    }
}
/**
 * Event handler function that displays swatch tooltip on tab focus per current target,
 * if the currentTarget is currently active.
 */
function showTooltipFocus(e) {
    var currentTooltip = $(e.currentTarget).siblings('.swatch-tooltip');
    currentTooltip.css('display', 'inline-block');
    //Adds the swatch-option divs to the tab order to focus on
    currentTooltip.children('.swatch-option').each(function () {
        $(this).children('.swatch-tooltip__caption').attr('tabindex', '0');
        //Set Tab index to -1 as the div caption will be used for focusing
        $(this).attr('tabindex', '-1');
    });
    //Tooltip is getting skipped due to swatches being the next element to tab to, hiding them causes the next available element to be the tooltip
    $('.swatches').each(function () {
        $(this).attr('tabindex', '-1');
    });
}
/**
 * Event handler function that hides swatch tooltip off tab focus per current target,
 * if the key down isn't shift.
 */
function hideTooltipFocus(e) {
    var eventTarget = $(e.currentTarget);
    if (!e.shiftKey && e.keyCode === 9) {
        //Readd swatches to tabbing order
        $('.swatches').each(function () {
            $(this).attr('tabindex', '0');
        });
        eventTarget.parents('.swatch-tooltip').css('display', 'none');
        //Remove the current tooltip swatches from the tabbing order
        eventTarget
            .parents('.swatch-tooltip')
            .children('.swatch-option')
            .each(function () {
                $(this).children('.swatch-tooltip__caption').attr('tabindex', '-1');
            });
    } else {
        e.currentTarget.focus();
    }
}

function swatchOverride() {
    $('.swatch-tooltip__caption').on('focus mouseover touchstart', function (e) {
        e.preventDefault();
        let tile = $(this).closest('.product-tile');
        var swatchOptionParent = $(this).parents('.swatch-option')[0];
        tile.find('.tile-image').attr('src', swatchOptionParent.dataset.productImg);
        tile.find('.product-tile-link').attr('href', swatchOptionParent.href);
        tile.find('.product-name-link').attr('href', swatchOptionParent.href);
        tile.find('.swatch-circle').removeClass('selected-plp');
        $(this).find('img.swatch-circle').addClass('selected-plp');
    });
}

function addToCart(element) {
    var addToCartUrl;
    var pid;
    var pidsObj;
    var setPids;
    var $productDetail = $('.product-detail');
    var gtmdata = $productDetail.data('pdpdetails');

    $('body').trigger('product:beforeAddToCart', element);

    if ($('.set-items').length && $(element).hasClass('add-to-cart-global')) {
        setPids = [];

        $('.product-detail').each(function () {
            if (!$(element).hasClass('product-set-detail')) {
                setPids.push({
                    pid: $(element).find('.product-id').text(),
                    qty: $(element).find('.quantity-select').val(),
                    options: getOptions($(element)),
                });
            }
        });
        pidsObj = JSON.stringify(setPids);
    }

    pid = getPidValue($(element));

    var $productContainer = $(element).closest('.product-detail');
    if (!$productContainer.length) {
        $productContainer = $(element).closest('.quick-view-dialog').find('.product-detail');
    }

    addToCartUrl = getAddToCartUrl();

    var form = {
        pid: pid,
        pidsObj: pidsObj,
        childProducts: getChildProducts(),
        quantity: getQuantitySelected($(element)),
    };

    var $productContainer = $(element).closest('.product-detail');
    if (!$productContainer.length) {
        $productContainer = $(element).closest('.modal-content').find('.product-quickview');
    }

    var color = $('.swatch-attributes-container.order-1')
        .find('a.selectable.selected')
        .find('span')
        .attr('data-attr-display-value');
    var pSize = $('.btn-size.selected', $productContainer).data('attr-value');

    var pWidth = $('.btn-width.selected', $productContainer).data('attr-value');

    if (color != null) {
        gtmdata.color = color;
    } else {
        gtmdata.color = null;
    }

    if (pSize != null) {
        gtmdata.size = parseFloat(pSize);
    } else {
        gtmdata.size = null;
    }

    if (pWidth != null) {
        gtmdata.width = pWidth;
    } else {
        gtmdata.width = null;
    }

    gtmdata.qty = getQuantitySelected($(element));
    gtmdata.masterid = $productDetail.data('masterid');

    if (!$('.bundle-item').length) {
        form.options = getOptions($productContainer);
    }

    $(element).trigger('updateAddToCartFormData', form);
    if (addToCartUrl) {
        form.addedFromStoreId = $('body')[0].id;
        $.ajax({
            url: addToCartUrl,
            method: 'POST',
            data: form,
            success: function (data) {
                handlePostCartAdd(data);
                $('body').trigger('product:afterAddToCart', data);
                setdatalayer.AddToCart(gtmdata);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            },
        });
    }
}

function initializeSwatches() {
    var delay = 500;
    var setTimeoutId;
    $('.swatch-option').on('mouseover', function (e) {
        const swatch = $(this);
        setTimeoutId = setTimeout(function () {
            swatch.nextAll('.swatch-text-container').first().find('.swatch-alt-text').show();
        }, delay);
    });
    $('.swatch-option').on('mouseout', function (e) {
        clearTimeout(setTimeoutId);
        $(this).nextAll('.swatch-text-container').first().find('.swatch-alt-text').hide();
    });
}

function slickCarouselADA() {
    $(document).on('click', 'button.slick-arrow', function (e) {
        if ($(this).hasClass('slick-next')) {
            var slides = $(this).siblings('.slick-list').find('.slick-active');
            var imgAlt = $(slides[slides.length - 1])
                .find('img')
                .attr('alt');
            if ($(this).closest('.slick-carousel').hasClass('home-carousel')) {
                $('.home-carousel-updates').html(imgAlt ? imgAlt : '');
            } else {
                $(this)
                    .closest('.slick-carousel')
                    .parent()
                    .find('.carousel-updates')
                    .html(imgAlt ? imgAlt : '');
            }
        } else if ($(this).hasClass('slick-prev')) {
            var slides = $(this).siblings('.slick-list').find('.slick-active');
            var imgAlt = $(slides[0]).find('img').attr('alt');
            if ($(this).closest('.slick-carousel').hasClass('home-carousel')) {
                $('.home-carousel-updates').html(imgAlt ? imgAlt : '');
            } else {
                $(this)
                    .closest('.slick-carousel')
                    .parent()
                    .find('.carousel-updates')
                    .html(imgAlt ? imgAlt : '');
            }
        }
    });
}

module.exports = {
    iniatilize: function () {
        $(document).ready(function () {
            swatchOverride();
            initializeSwatches();
            $(document).on('mouseover touchstart', '.swatch-option', function (e) {
                initializeSwatches();
                swatchOverride();
            });
            slickCarouselADA();
        });
    },
    attributeSelect: attributeSelect,
    methods: {
        editBonusProducts: function (data) {
            chooseBonusProducts(data);
        },
    },
    colorAttribute: function () {
        $(document).on('click', '[data-attr="color"] a', function (e) {
            e.preventDefault();

            if (
                $(this).attr('disabled') ||
                ($('[data-attr="color"] a').length === 1 && $('[data-attr="color"] a').hasClass('selected'))
            ) {
                return;
            }
            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }

            attributeSelect(e.currentTarget.href, $productContainer);
        });
    },

    buttonAttribute: function () {
        $(document).on('click', '.pdp-variation-button', function (e) {
            e.preventDefault();

            if ($('.pdp-variation-button').length === 1 && $('.pdp-variation-button').hasClass('selected')) {
                return;
            }

            // de-select all buttons within the current group
            $(this).parent().find('.pdp-variation-button').removeClass('selected').attr('aria-pressed', false);

            $(this).attr('aria-pressed', true);

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }

            attributeSelect(e.currentTarget.href, $productContainer);
        });
    },

    selectAttribute: function () {
        $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            attributeSelect(e.currentTarget.value, $productContainer);
        });
    },

    availability: function () {
        $(document).on('change', '.quantity-select', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.modal-content').find('.product-quickview');
            }

            if ($('.bundle-items', $productContainer).length === 0) {
                attributeSelect($(e.currentTarget).find('option:selected').data('url'), $productContainer);
            }
        });
    },

    addToCart: function () {
        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function () {
            addToCart(this);
        });
    },

    buyNow: function () {
        $(document).on('click', 'button.buy-now', function () {
            addToCart(this);

            window.location.href = $('.buy-now-url').val();
        });
    },

    selectBonusProduct: function () {
        $(document).on('click', '.select-bonus-product', function () {
            var $choiceOfBonusProduct = $(this).parents('.choice-of-bonus-product');
            var pid = $(this).data('pid');
            var maxPids = $('.choose-bonus-product-dialog').data('total-qty');
            var submittedQty = parseInt(
                $(this).parents('.choice-of-bonus-product').find('.bonus-quantity-select').val(),
                10
            );
            var totalQty = 0;
            $.each($('#chooseBonusProductModal .selected-bonus-products .selected-pid'), function () {
                totalQty += $(this).data('qty');
            });
            totalQty += submittedQty;
            var optionID = $(this).parents('.choice-of-bonus-product').find('.product-option').data('option-id');
            var valueId = $(this)
                .parents('.choice-of-bonus-product')
                .find('.options-select option:selected')
                .data('valueId');
            if (totalQty <= maxPids) {
                var selectedBonusProductHtml =
                    '' +
                    '<div class="selected-pid row" ' +
                    'data-pid="' +
                    pid +
                    '"' +
                    'data-qty="' +
                    submittedQty +
                    '"' +
                    'data-optionID="' +
                    (optionID || '') +
                    '"' +
                    'data-option-selected-value="' +
                    (valueId || '') +
                    '"' +
                    '>' +
                    '<div class="col-sm-16 col-13 bonus-product-name" >' +
                    $choiceOfBonusProduct.find('.product-name').html() +
                    '</div>' +
                    '<div class="col-2"><i class="fa fa-times" aria-hidden="true"></i></div>' +
                    '</div>';
                $('#chooseBonusProductModal .selected-bonus-products').append(selectedBonusProductHtml);
                $('.pre-cart-products').html(totalQty);
                $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
            } else {
                $('.selected-bonus-products .bonus-summary').addClass('alert-danger');
            }
        });
    },
    removeBonusProduct: function () {
        $(document).on('click', '.selected-pid', function () {
            $(this).remove();
            var $selected = $('#chooseBonusProductModal .selected-bonus-products .selected-pid');
            var count = 0;
            if ($selected.length) {
                $selected.each(function () {
                    count += parseInt($(this).data('qty'), 10);
                });
            }

            $('.pre-cart-products').html(count);
            $('.selected-bonus-products .bonus-summary').removeClass('alert-danger');
        });
    },
    enableBonusProductSelection: function () {
        $('body').on('bonusproduct:updateSelectButton', function (e, response) {
            $('button.select-bonus-product', response.$productContainer).attr(
                'disabled',
                !response.product.readyToOrder || !response.product.available
            );
            var pid = response.product.id;
            $('button.select-bonus-product').data('pid', pid);
        });
    },
    showMoreBonusProducts: function () {
        $(document).on('click', '.show-more-bonus-products', function () {
            var url = $(this).data('url');
            $('.modal-content').spinner().start();
            $.ajax({
                url: url,
                method: 'GET',
                success: function (html) {
                    var parsedHtml = parseHtml(html);
                    $('.modal-body').append(parsedHtml.body);
                    $('.show-more-bonus-products:first').remove();
                    $('.modal-content').spinner().stop();
                },
                error: function () {
                    $('.modal-content').spinner().stop();
                },
            });
        });
    },
    addBonusProductsToCart: function () {
        $(document).on('click', '.add-bonus-products', function () {
            var $readyToOrderBonusProducts = $('.choose-bonus-product-dialog .selected-pid');
            var queryString = '?pids=';
            var url = $('.choose-bonus-product-dialog').data('addtocarturl');
            var pidsObject = {
                bonusProducts: [],
            };

            $.each($readyToOrderBonusProducts, function () {
                var qtyOption = parseInt($(this).data('qty'), 10);

                var option = null;
                if (qtyOption > 0) {
                    if ($(this).data('optionid') && $(this).data('option-selected-value')) {
                        option = {};
                        option.optionId = $(this).data('optionid');
                        option.productId = $(this).data('pid');
                        option.selectedValueId = $(this).data('option-selected-value');
                    }
                    pidsObject.bonusProducts.push({
                        pid: $(this).data('pid'),
                        qty: qtyOption,
                        options: [option],
                    });
                    pidsObject.totalQty = parseInt($('.pre-cart-products').html(), 10);
                }
            });
            queryString += JSON.stringify(pidsObject);
            queryString = queryString + '&uuid=' + $('.choose-bonus-product-dialog').data('uuid');
            queryString = queryString + '&pliuuid=' + $('.choose-bonus-product-dialog').data('pliuuid');
            $.spinner().start();
            $.ajax({
                url: url + queryString,
                method: 'POST',
                success: function (data) {
                    $.spinner().stop();
                    if (data.error) {
                        $('.error-choice-of-bonus-products').html(data.errorMessage);
                    } else {
                        $('.configure-bonus-product-attributes').html(data);
                        $('.bonus-products-step2').removeClass('hidden-xl-down');
                        $('#chooseBonusProductModal').modal('hide');

                        if ($('.add-to-cart-messages').length === 0) {
                            $('body').append('<div class="add-to-cart-messages"></div>');
                        }
                        $('.minicart-quantity').html(data.totalQty);
                        $('.add-to-cart-messages').append(
                            '<div class="alert alert-success add-to-basket-alert text-center"' +
                                ' role="alert">' +
                                data.msgSuccess +
                                '</div>'
                        );
                        setTimeout(function () {
                            $('.add-to-basket-alert').remove();
                            if ($('.cart-page').length) {
                                location.reload();
                            }
                        }, 3000);
                    }
                },
                error: function () {
                    $.spinner().stop();
                },
            });
        });
    },
    openSocialLink: function () {
        $(document).on('click', '.social-container a', function (e) {
            var $link = $(this),
                productID = $('.product-number .product-id').text(),
                productURL = $link.parents('.social-container').data('producturl').replace('PRODUCT_ID', productID),
                imageURL = $('.primary-image-carousel .slick-current img').attr('src'),
                socialURL = $link
                    .data('socialurl')
                    .replace('PRODUCT_URL', encodeURIComponent(productURL))
                    .replace('IMAGE_URL', encodeURIComponent(imageURL));

            $link.attr('href', socialURL);
        });
    },

    getPidValue: getPidValue,

    updateStockNumber: updateStockNumber,

    initializeImages: function () {
        var $productContainer = $('.product-detail'),
            $selectAttr = $('[data-attr] select'),
            $colorAttr = $('[data-attr="color"] a.selectable'),
            $sizeAttr = $('[data-attr="size"] a.selectable:not(.strikethrough)'),
            $widthAttr = $('[data-attr="width"] a.selectable:not(.strikethrough)');

        if ($colorAttr.length > 0) {
            // There is 1 or more color swatches...
            if ($colorAttr.find('.selected').length === 0) {
                // ...and none of them are selected.
                // Auto-select the first color to display the C1 MAP product price and the correct product images.
                $colorAttr.first().click();
            } else {
                // ...and one of them is selected.
                if ($selectAttr.length === 0 && $sizeAttr.length === 0 && $widthAttr.length === 0) {
                    // Accessory product with variant (likely color variant) pre-selected in URL leads here
                    // There are no dropdown attributes or button attributes.
                    // Extract and display the stock number and display the correct product images.
                    var stockNumber = $('[data-pid]').first().data('pid').split('-')[1].slice(0, 6);
                    updateStockNumber(stockNumber);
                    updateProductImages();
                } else {
                    // Product with variant pre-selected leads here
                    // There is at least 1 dropdown attribute.
                    // Trigger the first dropdown attribute to display the price for C1 MAP products and the correct product images.
                    attributeSelect($productContainer.data('pdpdetails').selectedVariantUrl, $productContainer);
                }
            }
        } else if ($selectAttr.length > 0) {
            // gift card PDP should trigger this block
            // There are no color swatches, but there is at least 1 dropdown attribute.
            // Trigger the first dropdown attribute to display the price for C1 MAP products and the correct product images.
            attributeSelect($productContainer.data('pdpdetails').selectedVariantUrl, $productContainer);
        } else {
            // There are no swatch and dropdown attributes, just display the correct product images.
            updateProductImages();
        }
    },
};
