'use strict';

module.exports = {
    trapFocus: function (afterCloseElement, isQuickview = false) {
        var focusableEls = $('.modal-content').find('select, input, textarea, button, a').filter(':visible');
        if (isQuickview) {
            var firstFocusableEl = focusableEls[1];
            var lastFocusableEl = focusableEls[focusableEls.length - 2];
        } else {
            var firstFocusableEl = focusableEls[0];
            var lastFocusableEl = focusableEls[focusableEls.length - 1];
        }
        var KEYCODE_TAB = 9;
        firstFocusableEl.focus();
        // remove previous listener first
        $('.modal-content').off('keydown');
        $('.modal-content').on('keydown', function (e) {
            if (e.key === 'Escape') {
                $('.close').trigger('click');
            }
            var isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;

            if (!isTabPressed) {
                return;
            }

            if (e.shiftKey) {
                /* shift + tab */ if (document.activeElement === firstFocusableEl) {
                    lastFocusableEl.focus();
                    e.preventDefault();
                }
            } /* tab */ else {
                if (document.activeElement === lastFocusableEl) {
                    firstFocusableEl.focus();
                    e.preventDefault();
                }
            }
        });
        $('.close').on('click', function (e) {
            $('.modal-content').off('keydown');
            // the quickview modal has its own method to change the focus after the modal is closed
            if (!isQuickview) {
                afterCloseElement.focus();
            }
            e.preventDefault();
        });
    },
};
