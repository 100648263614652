'use strict';

var gtmContainer = require('../gtm/container');
var lazyload = require('lazyload');

function addPauseButton() {
    $('.slick-carousel').each(function () {
        var $slider = $(this);
        if ($slider.slick('slickGetOption', 'autoplay') === true) {
            $slider.prepend(`
				<button class="pause-autoplay" aria-pressed="false">
					<span class="sr-only">Pause Slideshow</span>
					<i class="fa fa-pause"></i>
				</button>
			`);

            var pause = true;
            $('.pause-autoplay', $slider).on('click', function () {
                $slider.slick(pause ? 'slickPause' : 'slickPlay');
                $(this).attr('aria-pressed', pause ? 'true' : 'false');
                $('i', $slider).attr('class', pause ? 'fa fa-play' : 'fa fa-pause');
                $('.sr-only', $slider).text(pause ? 'Pause Slideshow' : 'Play Slideshow');
                pause = !pause;
            });
        }
    });
}

function productCarousel() {
    $('.product-carousel')
        .on('beforeChange', function (e, slick, currentSlide, nextSlide) {
            var direction;
            if (Math.abs(nextSlide - currentSlide) == 1) {
                direction = nextSlide - currentSlide > 0 ? 'right' : 'left';
            } else {
                direction = nextSlide - currentSlide > 0 ? 'left' : 'right';
            }

            $(this).attr('data-direction', direction);
            if (direction === 'left') {
                $('.slick-slide.slick-active', this).first().prev().find('a').first().focus();
            }
        })
        .on('afterChange', function (e, slick) {
            if ($(this).attr('data-direction') === 'right') {
                $('.slick-slide.slick-active', this).last().find('a').first().focus();
            }
        });
}

module.exports = {
    initialize: function () {
        $(document).ready(function () {
            gtmContainer.initPosition();
            $('.slick-carousel:not(.slick-initialized)').slick();
            $('.lazyload').lazyload();
            addPauseButton();
            productCarousel();
        });
    },

    refresh: function () {
        $('.slick-carousel.slick-initialized').slick('setPosition');
        $('.lazyload').lazyload();
    },
};
