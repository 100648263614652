/* eslint-disable newline-per-chained-call */
/* eslint-disable func-names */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable vars-on-top */
'use strict';
var base = require('./base');
var modalFocus = require('../modalFocus');

/**
 * Generates the modal window on the first call.
 *@param {string} productName - Name of the Product to be appended in full-pdp-link text
 */
function getModalHtmlElement(productName, productId) {
    if ($('#quickViewModal').length !== 0) {
        $('#quickViewModal').remove();
    }
    var htmlString =
        '<!-- Modal -->' +
        '<div class="modal fade" id="quickViewModal" role="dialog" aria-labelledby="product-heading-' +
        productId +
        '">' +
        '<div class="modal-dialog quick-view-dialog" aria-labelledby="modal-header">' +
        '<!-- Modal content-->' +
        '<div class="modal-content">' +
        '<div class="modal-header" id="modal-header">' +
        '    <a class="full-pdp-link visually-hidden" href="" title="View Full Details of ' +
        productName +
        '">View Full Details of ' +
        productName +
        '</a>' +
        '    <button aria-label="Close" tabindex="0" type="button" class="close pull-right" data-dismiss="modal">' +
        '        &times;' +
        '    </button>' +
        '</div>' +
        '<div class="modal-body"></div>' +
        '<button tabindex="0" type="button" class="close visually-hidden" data-dismiss="modal">' +
        '&times;' +
        '</button>' +
        '<div class="modal-footer"></div>' +
        '</div>' +
        '</div>' +
        '</div>';
    $('body').append(htmlString);
}

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} productUrl - url to be used for going to the product details page
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function fillModalElement(productUrl, selectedValueUrl) {
    $('.modal-body').spinner().start();
    $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'html',
        success: function (html) {
            var parsedHtml = parseHtml(html);

            $('.modal-body').empty();
            // $('.modal-body').html(html);
            $('.modal-body').html(parsedHtml.body);
            $('.modal-footer').html(parsedHtml.footer);
            $('#quickViewModal .full-pdp-link').attr('href', productUrl);
            $('#quickViewModal .size-chart').attr('href', productUrl);
            $('#quickViewModal').modal('show');

            setTimeout(function () {
                base.initializeImages();
                $('#quickViewModal').find('a').eq(0).focus();
            }, 300);

            $.spinner().stop();
            setTimeout(function () {
                // empty first parameter since quickView has its own method to handle changing focus on modal close
                modalFocus.trapFocus($(''), true);
            }, 500);
        },
        error: function () {
            $.spinner().stop();
        },
    });
}

/**
 * On Modal Close, Focuses on the Product Quickview button that triggered the Modal
 *
 */
function focusOnLastClick() {
    var pid = $('.product-quickview').data('pid');
    setTimeout(function () {
        if ($('#quickViewModal').css('display') === 'none') {
            $('.product-grid')
                .find('[data-pid=' + pid + ']')
                .attr('tabindex', -1)
                .find('a.quickview')
                .focus();
            // Remove aria-hidden
            $('.page').removeAttr('aria-hidden');
        }
    }, 300);
}

/**
 * Adds Exit Modal action on Tab and then uses focusOnLastClick() function
 * @param {string} classParam - The class selector to handle the Action
 */
function onTabExitModal(classParam) {
    $('body').on('keydown', classParam, function (e) {
        if (e.key === 'Tab') {
            $('button.close').focus();
        }
    });
}

module.exports = {
    initialize: base.iniatilize(),
    showQuickview: function () {
        $('body').on('click', '.quickview', function (e) {
            e.preventDefault();
            var selectedValueUrl = $(this).closest('a.quickview').attr('href');
            var productUrl = selectedValueUrl.replace('Product-ShowQuickView', 'Product-Show');
            $(e.target).trigger('quickview:show');

            var productGrid = $(this).closest('.product-tile');
            var productName = $(productGrid).find('.product-name').text();
            var productId = $(productGrid).find('.product-id').text();
            getModalHtmlElement(productName, productId);
            fillModalElement(productUrl, selectedValueUrl);
            onTabExitModal('.full-pdp-link:eq(1)');
            $('.page').attr('aria-hidden', true);
        });
        // On Modal Exit, Focus on the Product that triggered the Modal
        $('body').on('click', '#quickViewModal', function () {
            focusOnLastClick();
        });
    },
    colorAttribute: base.colorAttribute,
    selectAttribute: base.selectAttribute,
    buttonAttribute: base.buttonAttribute,
    removeBonusProduct: base.removeBonusProduct,
    selectBonusProduct: base.selectBonusProduct,
    enableBonusProductSelection: base.enableBonusProductSelection,
    showMoreBonusProducts: base.showMoreBonusProducts,
    addBonusProductsToCart: base.addBonusProductsToCart,
    availability: base.availability,
    addToCart: base.addToCart,
    buyNow: base.buyNow,
    showSpinner: function () {
        $('body').on('product:beforeAddToCart', function (e, data) {
            $(data).closest('.modal-content').spinner().start();
        });
    },
    hideDialog: function () {
        $('body').on('product:afterAddToCart', function () {
            $('#quickViewModal').modal('hide');
        });
    },
    beforeUpdateAttribute: function () {
        $('body').on('product:beforeAttributeSelect', function () {
            $('.modal.show .modal-content').spinner().start();
        });
    },
    updateAttribute: function () {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            if ($('.modal.show .product-quickview>.bundle-items').length) {
                $('.modal.show').find(response.container).data('pid', response.data.product.id);
                $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
            } else if ($('.set-items').length) {
                response.container.find('.product-id').text(response.data.product.id);
            } else {
                $('.modal.show .product-quickview').data('pid', response.data.product.id);
                $('.modal.show .full-pdp-link').attr('href', response.data.product.selectedProductUrl);
            }
            base.updateStockNumber(response.data.product.stockNumber);
        });
    },
    updateAddToCart: function () {
        $('body').on('product:updateAddToCart', function (e, response) {
            // update local add to cart (for sets)
            $('button.add-to-cart', response.$productContainer).attr(
                'disabled',
                !response.product.readyToOrder || !response.product.available
            );

            // update global add to cart (single products, bundles)
            var dialog = $(response.$productContainer).closest('.quick-view-dialog');

            $('.add-to-cart-global', dialog).attr(
                'disabled',
                !$('.global-availability', dialog).data('ready-to-order') ||
                    !$('.global-availability', dialog).data('available')
            );

            $('button.buy-now', dialog).attr(
                'disabled',
                !$('.global-availability', dialog).data('ready-to-order') ||
                    !$('.global-availability', dialog).data('available')
            );
        });
    },
    updateAvailability: function () {
        $('body').on('product:updateAvailability', function (e, response) {
            // bundle individual products
            $('.product-availability', response.$productContainer)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);

            var dialog = $(response.$productContainer).closest('.quick-view-dialog');

            if ($('.product-availability', dialog).length) {
                // bundle all products
                var allAvailable = $('.product-availability', dialog)
                    .toArray()
                    .every(function (item) {
                        return $(item).data('available');
                    });

                var allReady = $('.product-availability', dialog)
                    .toArray()
                    .every(function (item) {
                        return $(item).data('ready-to-order');
                    });

                $('.global-availability', dialog).data('ready-to-order', allReady).data('available', allAvailable);

                $('.global-availability .availability-msg', dialog)
                    .empty()
                    .html(allReady ? response.message : response.resources.info_selectforstock);
            } else {
                // single product
                $('.global-availability', dialog)
                    .data('ready-to-order', response.product.readyToOrder)
                    .data('available', response.product.available)
                    .find('.availability-msg')
                    .empty()
                    .html(response.message);
            }
        });
    },
    openSocialLink: base.openSocialLink,
};
